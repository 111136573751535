import {IOption} from "src/Components/Fields/Select.field.component";

const serverUrl: string = process.env.REACT_APP_SERVER_URL;
const apiUrl: string = serverUrl + 'api/';
const useSentry: string = process.env.REACT_APP_ALLOW_SENTRY;

type IAppConstants = {
    serverUrl: string,
    apiUrl: string,
    bearer: string,
    useSentry: boolean,
    emailContactDefaut: string,

    pathLogin: string,
    pathHome: string,
    pathMosaique: string,
    pathAnnuaire: string,
    pathPasswordLost: string,
    path404: string,
    path403: string,
    pathUtilisateurs: string,
    pathOffices: string,
    pathAnnexes: string,
    pathNotaires: string,
    pathNotairesAnciens: string,
    pathFormationsRoot: string,
    pathFormations: string,
    pathAutresContacts: string,
    pathOrganismes: string,
    pathPersonnesExterieures: string,
    pathCertifications: string,
    pathLabels: string,
    pathGroupes: string,
    pathSuccessions: string,
    pathUrssaf: string,
    pathTableauBord: string,
    pathCtrcs: string,
    pathMosaiqueSinistres: string,
    pathSinistres: string,
    pathDifferends: string,
    pathInspections: string,
    pathHabilitations: string,
    pathClassements: string,
    pathStatistiques: string,
    pathReclamations: string,
    pathParametresFormations: string,
    pathParametresCotisations: string,
    pathParametres: string,
    pathCotisations: string,
    pathFilInformations: string,
    pathFilInformationsAbonnes: string,
    pathCorrespondances: string,
    pathCotisationsListeOffices: string,
    pathCotisationsSaisieNotaires: string,
    pathCotisationsSaisieOffices: string,
    pathLettreTypes: string,
    pathSignatures: string,
    pathFilInformationsMiseAJourInscriptionExternal: string,
    pathCampagneModificationOfficeExternal: string,
    pathCampagneModificationCooordonneeExternal: string,
    pathCampagneModificationCoordonneeVerificationExternal: string,

    ouiNonOptions: IOption[],
    ouiNonTousOptions: IOption[],
    ouiNonPasDeFiltreOptions: IOption[],
    sexeOptions: IOption[],
    officeTypesOptions: IOption[],
    civiliteCourteOptions: IOption[],
    orderType: {
        [key:string] : string
    },
    orderDirectionOptions: IOption[],
    nationalitesOptions: IOption[],
}

export const AppConstants: IAppConstants = {
    serverUrl: serverUrl,
    apiUrl: apiUrl,
    bearer: "IyD52BfNOs8mKGYbNZRbJMTEprDwaDBiMAVJdZ53",
    emailContactDefaut: "accueil.ci.caen@notaires.fr",

    pathLogin: "/connexion",
    pathHome: "/",
    pathMosaique: "/mosaique/",
    pathAnnuaire: "/mosaique/#annuaire",
    pathPasswordLost: '/mot-de-passe-oublie',
    path404: "",
    path403: "/acces-non-autorise",
    pathUtilisateurs: "/utilisateurs",
    pathOffices: "/annuaire/offices",
    pathAnnexes: "/annuaire/annexes",
    pathNotaires: "/annuaire/notaires",
    pathNotairesAnciens: "/annuaire/anciens-notaires",
    pathFormationsRoot: "/#formations",
    pathFormations: "/formations",
    pathAutresContacts: "/annuaire/autres-contacts",
    pathOrganismes: "/annuaire/organismes",
    pathPersonnesExterieures: "/annuaire/personnes-exterieures",
    pathCertifications: "/annuaire/certifications",
    pathLabels: "/annuaire/labels",
    pathGroupes: "/annuaire/groupes",
    pathSuccessions: "/successions",
    pathUrssaf: "/formations/urssaf",
    pathTableauBord: "/tableau-de-bord",
    pathSinistres: "/sinistres",
    pathMosaiqueSinistres: "/mosaique/#sinistres",
    pathCtrcs: "/ctrcs",
    pathDifferends: "/differends",
    pathInspections: "/inspections",
    pathHabilitations: "/inspections/habilitations",
    pathClassements: "/inspections/classements",
    pathStatistiques: "/inspections/statistiques",
    pathReclamations: "/reclamations",

    pathParametresFormations: "/formations/parametres",
    pathParametresCotisations: "/cotisations/parametres",
    pathParametres: "/parametres",

    pathCotisations: "/cotisations",
    pathCotisationsListeOffices: "/cotisations/offices",
    pathCotisationsSaisieNotaires: "/cotisations/saisie-notaires",
    pathCotisationsSaisieOffices: "/cotisations/saisie-offices",

    pathFilInformations: "/fil-infos",
    pathFilInformationsAbonnes: "/fil-infos/abonnes",
    pathFilInformationsMiseAJourInscriptionExternal: "/fil-infos/abonnement/mise-a-jour/:email",

    pathCampagneModificationOfficeExternal: "/campagnes-modifications-offices/:ctoken",
    pathCampagneModificationCooordonneeExternal: "/campagnes-modifications-coordonnees/:ctoken",
    pathCampagneModificationCoordonneeVerificationExternal: "/campagnes-modifications-coordonnees/confirmation/:ctoken",

    //TODO Constantes pour les autres campagnes de modifications !

    pathCorrespondances: "/correspondances/courriers",
    pathLettreTypes: "/correspondances/lettres-types",
    pathSignatures: "/correspondances/signatures",

    useSentry: useSentry && useSentry == "true",

    ouiNonOptions: [
        {
            value: "oui",
            label: "Oui"
        },
        {
            value: "non",
            label: "Non"
        },
    ],

    ouiNonTousOptions: [
        {
            value: "oui",
            label: "Oui"
        },
        {
            value: "non",
            label: "Non"
        },
        {
            value: "tous",
            label: "Tous"
        },
    ],

    ouiNonPasDeFiltreOptions: [
        {
            value: "oui",
            label: "Oui"
        },
        {
            value: "non",
            label: "Non"
        },
        {
            value: "tous",
            label: "Pas de filtre"
        },
    ],
    sexeOptions: [
        {
            value: "nonPrecise",
            label: "Non précisé"
        },
        {
            value: "masculin",
            label: "Masculin"
        },
        {
            value: "feminin",
            label: "Féminin"
        },
    ],
    orderType: {
        "asc": "ASC",
        "desc": "DESC"
    },

    officeTypesOptions: [
        {
            value: "principale",
            label: "Etude principale"
        },
        {
            value: "annexe",
            label: "Bureaux ou Annexes rattaché(e)s à cet office"
        },
    ],

    civiliteCourteOptions: [
        {
            value: "madame",
            label: "Madame"
        },
        {
            value: "monsieur",
            label: "Monsieur"
        },
        {
            value: "maitre",
            label: "Maître"
        },
    ],

    orderDirectionOptions: [
        {
            value: "ASC",
            label: "Ascendant"
        },
        {
            value: "DESC",
            label: "Descendant"
        },
    ],

    //Source : https://lucidar.me/fr/web-dev/drop-down-list-of-citizenships/
    //Liste des nationalités en ISO 3166-1 alpha-3
    nationalitesOptions: [
        {value:"FRA", label:"Française (France)"},
        {value:"AFG", label:"Afghane (Afghanistan)"},
        {value:"ALB", label:"Albanaise (Albanie)"},
        {value:"DZA", label:"Algérienne (Algérie)"},
        {value:"DEU", label:"Allemande (Allemagne)"},
        {value:"USA", label:"Americaine (États-Unis)"},
        {value:"AND", label:"Andorrane (Andorre)"},
        {value:"AGO", label:"Angolaise (Angola)"},
        {value:"ATG", label:"Antiguaise-et-Barbudienne (Antigua-et-Barbuda)"},
        {value:"ARG", label:"Argentine (Argentine)"},
        {value:"ARM", label:"Armenienne (Arménie)"},
        {value:"AUS", label:"Australienne (Australie)"},
        {value:"AUT", label:"Autrichienne (Autriche)"},
        {value:"AZE", label:"Azerbaïdjanaise (Azerbaïdjan)"},
        {value:"BHS", label:"Bahamienne (Bahamas)"},
        {value:"BHR", label:"Bahreinienne (Bahreïn)"},
        {value:"BGD", label:"Bangladaise (Bangladesh)"},
        {value:"BRB", label:"Barbadienne (Barbade)"},
        {value:"BEL", label:"Belge (Belgique)"},
        {value:"BLZ", label:"Belizienne (Belize)"},
        {value:"BEN", label:"Béninoise (Bénin)"},
        {value:"BTN", label:"Bhoutanaise (Bhoutan)"},
        {value:"BLR", label:"Biélorusse (Biélorussie)"},
        {value:"MMR", label:"Birmane (Birmanie)"},
        {value:"GNB", label:"Bissau-Guinéenne (Guinée-Bissau)"},
        {value:"BOL", label:"Bolivienne (Bolivie)"},
        {value:"BIH", label:"Bosnienne (Bosnie-Herzégovine)"},
        {value:"BWA", label:"Botswanaise (Botswana)"},
        {value:"BRA", label:"Brésilienne (Brésil)"},
        {value:"GBR", label:"Britannique (Royaume-Uni)"},
        {value:"BRN", label:"Brunéienne (Brunéi)"},
        {value:"BGR", label:"Bulgare (Bulgarie)"},
        {value:"BFA", label:"Burkinabée (Burkina)"},
        {value:"BDI", label:"Burundaise (Burundi)"},
        {value:"KHM", label:"Cambodgienne (Cambodge)"},
        {value:"CMR", label:"Camerounaise (Cameroun)"},
        {value:"CAN", label:"Canadienne (Canada)"},
        {value:"CPV", label:"Cap-verdienne (Cap-Vert)"},
        {value:"CAF", label:"Centrafricaine (Centrafrique)"},
        {value:"CHL", label:"Chilienne (Chili)"},
        {value:"CHN", label:"Chinoise (Chine)"},
        {value:"CYP", label:"Chypriote (Chypre)"},
        {value:"COL", label:"Colombienne (Colombie)"},
        {value:"COM", label:"Comorienne (Comores)"},
        {value:"COG", label:"Congolaise (Congo-Brazzaville)"},
        {value:"COD", label:"Congolaise (Congo-Kinshasa)"},
        {value:"COK", label:"Cookienne (Îles Cook)"},
        {value:"CRI", label:"Costaricaine (Costa Rica)"},
        {value:"HRV", label:"Croate (Croatie)"},
        {value:"CUB", label:"Cubaine (Cuba)"},
        {value:"DNK", label:"Danoise (Danemark)"},
        {value:"DJI", label:"Djiboutienne (Djibouti)"},
        {value:"DOM", label:"Dominicaine (République dominicaine)"},
        {value:"DMA", label:"Dominiquaise (Dominique)"},
        {value:"EGY", label:"Égyptienne (Égypte)"},
        {value:"ARE", label:"Émirienne (Émirats arabes unis)"},
        {value:"GNQ", label:"Équato-guineenne (Guinée équatoriale)"},
        {value:"ECU", label:"Équatorienne (Équateur)"},
        {value:"ERI", label:"Érythréenne (Érythrée)"},
        {value:"ESP", label:"Espagnole (Espagne)"},
        {value:"TLS", label:"Est-timoraise (Timor-Leste)"},
        {value:"EST", label:"Estonienne (Estonie)"},
        {value:"ETH", label:"Éthiopienne (Éthiopie)"},
        {value:"FJI", label:"Fidjienne (Fidji)"},
        {value:"FIN", label:"Finlandaise (Finlande)"},
        {value:"GAB", label:"Gabonaise (Gabon)"},
        {value:"GMB", label:"Gambienne (Gambie)"},
        {value:"GEO", label:"Georgienne (Géorgie)"},
        {value:"GHA", label:"Ghanéenne (Ghana)"},
        {value:"GRD", label:"Grenadienne (Grenade)"},
        {value:"GTM", label:"Guatémaltèque (Guatemala)"},
        {value:"GIN", label:"Guinéenne (Guinée)"},
        {value:"GUY", label:"Guyanienne (Guyana)"},
        {value:"HTI", label:"Haïtienne (Haïti)"},
        {value:"GRC", label:"Hellénique (Grèce)"},
        {value:"HND", label:"Hondurienne (Honduras)"},
        {value:"HUN", label:"Hongroise (Hongrie)"},
        {value:"IND", label:"Indienne (Inde)"},
        {value:"IDN", label:"Indonésienne (Indonésie)"},
        {value:"IRQ", label:"Irakienne (Iraq)"},
        {value:"IRN", label:"Iranienne (Iran)"},
        {value:"IRL", label:"Irlandaise (Irlande)"},
        {value:"ISL", label:"Islandaise (Islande)"},
        {value:"ISR", label:"Israélienne (Israël)"},
        {value:"ITA", label:"Italienne (Italie)"},
        {value:"CIV", label:"Ivoirienne (Côte d'Ivoire)"},
        {value:"JAM", label:"Jamaïcaine (Jamaïque)"},
        {value:"JPN", label:"Japonaise (Japon)"},
        {value:"JOR", label:"Jordanienne (Jordanie)"},
        {value:"KAZ", label:"Kazakhstanaise (Kazakhstan)"},
        {value:"KEN", label:"Kenyane (Kenya)"},
        {value:"KGZ", label:"Kirghize (Kirghizistan)"},
        {value:"KIR", label:"Kiribatienne (Kiribati)"},
        {value:"KNA", label:"Kittitienne et Névicienne (Saint-Christophe-et-Niévès)"},
        {value:"KWT", label:"Koweïtienne (Koweït)"},
        {value:"LAO", label:"Laotienne (Laos)"},
        {value:"LSO", label:"Lesothane (Lesotho)"},
        {value:"LVA", label:"Lettone (Lettonie)"},
        {value:"LBN", label:"Libanaise (Liban)"},
        {value:"LBR", label:"Libérienne (Libéria)"},
        {value:"LBY", label:"Libyenne (Libye)"},
        {value:"LIE", label:"Liechtensteinoise (Liechtenstein)"},
        {value:"LTU", label:"Lituanienne (Lituanie)"},
        {value:"LUX", label:"Luxembourgeoise (Luxembourg)"},
        {value:"MKD", label:"Macédonienne (Macédoine)"},
        {value:"MYS", label:"Malaisienne (Malaisie)"},
        {value:"MWI", label:"Malawienne (Malawi)"},
        {value:"MDV", label:"Maldivienne (Maldives)"},
        {value:"MDG", label:"Malgache (Madagascar)"},
        {value:"MLI", label:"Maliennes (Mali)"},
        {value:"MLT", label:"Maltaise (Malte)"},
        {value:"MAR", label:"Marocaine (Maroc)"},
        {value:"MHL", label:"Marshallaise (Îles Marshall)"},
        {value:"MUS", label:"Mauricienne (Maurice)"},
        {value:"MRT", label:"Mauritanienne (Mauritanie)"},
        {value:"MEX", label:"Mexicaine (Mexique)"},
        {value:"FSM", label:"Micronésienne (Micronésie)"},
        {value:"MDA", label:"Moldave (Moldovie)"},
        {value:"MCO", label:"Monegasque (Monaco)"},
        {value:"MNG", label:"Mongole (Mongolie)"},
        {value:"MNE", label:"Monténégrine (Monténégro)"},
        {value:"MOZ", label:"Mozambicaine (Mozambique)"},
        {value:"NAM", label:"Namibienne (Namibie)"},
        {value:"NRU", label:"Nauruane (Nauru)"},
        {value:"NLD", label:"Néerlandaise (Pays-Bas)"},
        {value:"NZL", label:"Néo-Zélandaise (Nouvelle-Zélande)"},
        {value:"NPL", label:"Népalaise (Népal)"},
        {value:"NIC", label:"Nicaraguayenne (Nicaragua)"},
        {value:"NGA", label:"Nigériane (Nigéria)"},
        {value:"NER", label:"Nigérienne (Niger)"},
        {value:"NIU", label:"Niuéenne (Niue)"},
        {value:"PRK", label:"Nord-coréenne (Corée du Nord)"},
        {value:"NOR", label:"Norvégienne (Norvège)"},
        {value:"OMN", label:"Omanaise (Oman)"},
        {value:"UGA", label:"Ougandaise (Ouganda)"},
        {value:"UZB", label:"Ouzbéke (Ouzbékistan)"},
        {value:"PAK", label:"Pakistanaise (Pakistan)"},
        {value:"PLW", label:"Palaosienne (Palaos)"},
        {value:"PSE", label:"Palestinienne (Palestine)"},
        {value:"PAN", label:"Panaméenne (Panama)"},
        {value:"PNG", label:"Papouane-Néo-Guinéenne (Papouasie-Nouvelle-Guinée)"},
        {value:"PRY", label:"Paraguayenne (Paraguay)"},
        {value:"PER", label:"Péruvienne (Pérou)"},
        {value:"PHL", label:"Philippine (Philippines)"},
        {value:"POL", label:"Polonaise (Pologne)"},
        {value:"PRT", label:"Portugaise (Portugal)"},
        {value:"QAT", label:"Qatarienne (Qatar)"},
        {value:"ROU", label:"Roumaine (Roumanie)"},
        {value:"RUS", label:"Russe (Russie)"},
        {value:"RWA", label:"Rwandaise (Rwanda)"},
        {value:"LCA", label:"Saint-Lucienne (Sainte-Lucie)"},
        {value:"SMR", label:"Saint-Marinaise (Saint-Marin)"},
        {value:"VCT", label:"Saint-Vincentaise et Grenadine (Saint-Vincent-et-les Grenadines)"},
        {value:"SLB", label:"Salomonaise (Îles Salomon)"},
        {value:"SLV", label:"Salvadorienne (Salvador)"},
        {value:"WSM", label:"Samoane (Samoa)"},
        {value:"STP", label:"Santoméenne (Sao Tomé-et-Principe)"},
        {value:"SAU", label:"Saoudienne (Arabie saoudite)"},
        {value:"SEN", label:"Sénégalaise (Sénégal)"},
        {value:"SRB", label:"Serbe (Serbie)"},
        {value:"SYC", label:"Seychelloise (Seychelles)"},
        {value:"SLE", label:"Sierra-Léonaise (Sierra Leone)"},
        {value:"SGP", label:"Singapourienne (Singapour)"},
        {value:"SVK", label:"Slovaque (Slovaquie)"},
        {value:"SVN", label:"Slovène (Slovénie)"},
        {value:"SOM", label:"Somalienne (Somalie)"},
        {value:"SDN", label:"Soudanaise (Soudan)"},
        {value:"LKA", label:"Sri-Lankaise (Sri Lanka)"},
        {value:"ZAF", label:"Sud-Africaine (Afrique du Sud)"},
        {value:"KOR", label:"Sud-Coréenne (Corée du Sud)"},
        {value:"SSD", label:"Sud-Soudanaise (Soudan du Sud)"},
        {value:"SWE", label:"Suédoise (Suède)"},
        {value:"CHE", label:"Suisse (Suisse)"},
        {value:"SUR", label:"Surinamaise (Suriname)"},
        {value:"SWZ", label:"Swazie (Swaziland)"},
        {value:"SYR", label:"Syrienne (Syrie)"},
        {value:"TJK", label:"Tadjike (Tadjikistan)"},
        {value:"TZA", label:"Tanzanienne (Tanzanie)"},
        {value:"TCD", label:"Tchadienne (Tchad)"},
        {value:"CZE", label:"Tchèque (Tchéquie)"},
        {value:"THA", label:"Thaïlandaise (Thaïlande)"},
        {value:"TGO", label:"Togolaise (Togo)"},
        {value:"TON", label:"Tonguienne (Tonga)"},
        {value:"TTO", label:"Trinidadienne (Trinité-et-Tobago)"},
        {value:"TUN", label:"Tunisienne (Tunisie)"},
        {value:"TKM", label:"Turkmène (Turkménistan)"},
        {value:"TUR", label:"Turque (Turquie)"},
        {value:"TUV", label:"Tuvaluane (Tuvalu)"},
        {value:"UKR", label:"Ukrainienne (Ukraine)"},
        {value:"URY", label:"Uruguayenne (Uruguay)"},
        {value:"VUT", label:"Vanuatuane (Vanuatu)"},
        {value:"VAT", label:"Vaticane (Vatican)"},
        {value:"VEN", label:"Vénézuélienne (Venezuela)"},
        {value:"VNM", label:"Vietnamienne (Viêt Nam)"},
        {value:"YEM", label:"Yéménite (Yémen)"},
        {value:"ZMB", label:"Zambienne (Zambie)"},
        {value:"ZWE", label:"Zimbabwéenne (Zimbabwe)"},
    ],
};

export type ICreationSteps = {
    url: string,
    title: string,
    icon: string,
}

export enum TabsSlugs {
    NOTAIRES = "notaires",
    ANCIENS_NOTAIRES = "ancien-notaires",
    COLLABORATEURS = "collaborateurs",
    EXTERIEURS = "exterieurs",
    A_VENIR = "a-venir",
    PASSEES = "passee",
    ARCHIVAGE = "archivage",
    EN_COURS = "en-cours",
    AUTRES = "autres",
    PENALITES = "penalite",
    SUIVI = "suivi",
    OFFICES_PRINCIPALES = "offices-principales",
    OFFICES_ANNEXES = "offices-annexes",
    OFFICES = "offices",
    ORGANISMES = "organismes",
    COURRIER = "courrier",
    LIST = "list",
    MAP = "map",
    CAMPAGNES_MODIFICATIONS = "campagnes-modifications",
    TEST_GENERATION = "test-list-generation",
    SINISTRES_LISTING = "sinistres-listing",
    SINISTRES_NOTAIRES_OBSERVATEURS = "sinistres-notaires-observateurs",
    EVENEMENTS = "evenements",


}
