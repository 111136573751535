import React, {useEffect, useState} from "react";
import BreadcrumbsComponent, {IBreadcrumbLink} from "../Breadcrumbs/Breadcrumbs.component";
import SearchBlockComponent, {IFormSearchData} from "../Search/SearchBlock.component";
import {ReactComponent as Logo} from "../../assets/images/logo-notaires-cours-appel-caen.svg";

const PageHeaderComponent = (props: {
    icon: string,
    text: string,
    subText?: string,
    modificators?: string,
    logo?: boolean,
    breadcrumbLinks?: IBreadcrumbLink[],
    onSearch?: (keyword: string) => void,
    onClickAdvancedSearch?: () => void,
    disableAdvancedSearch?: boolean,

    currentKeyword?: string,
}) => {
    const [keyword, setKeyword] = useState<string>("");

    useEffect(() => {
        //On a été mis à jour à l'extérieur
        //Dans un reset par exemple
        if (props.currentKeyword != keyword) {
            setKeyword(props.currentKeyword);
        }
    }, [props.currentKeyword])


    const onSearch = (formDatas: IFormSearchData) => {
        const searchedKeyword = formDatas.keyword as string;
        setKeyword(searchedKeyword);
        props.onSearch(searchedKeyword);
    };

    return <div className={`page-header ${props.modificators || ""}`}>
        <div className="left">
            {props.breadcrumbLinks && <BreadcrumbsComponent links={props.breadcrumbLinks} separator="/"/>}

            <h1 className="title">
                <i className={`icon ${props.icon}`}/>
                <span className="text">
                    {props.text}
                    {props.subText && <span className="sub"><span className="sep">/</span>{props.subText}</span>}
                </span>
            </h1>
        </div>

        {(props.logo != null && props.logo && props.onSearch == null) &&
            <div className="right">
                <Logo className="logo"/>
            </div>
        }

        {props.onSearch &&
            <div className="right">
                {props.onClickAdvancedSearch && !props.disableAdvancedSearch &&
                    <a href="/" className="advancedsearch"
                       onClick={(e) => {
                           e.preventDefault();
                           props.onClickAdvancedSearch()
                       }}>
                        Recherche avancée
                    </a>
                }
                <div className={`input-wrapper -with-aside `}>
                    <SearchBlockComponent placeholder={"Rechercher"} keyword={keyword} onSubmit={onSearch}
                                          modificators="-searchpage -list-header" searchAuto={true}/>
                </div>
            </div>
        }
    </div>
};

export default PageHeaderComponent;
