import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {INotaire} from "src/Models/Notaire.model";
import {FormComponentFormData} from "../Components/Forms/FormCreator.component";

export type INotairesGetPaginationApi = IApiPagination & {
    data: INotaire[],
}
export type INotairesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: INotairesGetPaginationApi
    }
}
export type INotaireGetApiResponse = IApiCustomResponse & {
    datas?: {
        notaire: INotaire
    }
}

export type INotairesEvenementsGetApiResponse = IApiCustomResponse & {
    datas: {
        evenements: INotairesEvenementsGetPaginationApi
    }
}

export type INotairesEvenementsGetPaginationApi = IApiPagination & {
    data: INotaireEvenement[]
}

export type INotaireEvenement = {
    notaireId: number,
    notaireNom: string,
    notairePrenom: string,
    notaireCSN: string,
    commentaire: string,
    dateDebut: string,
    dateFin: string,
}

export type INotaireGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    type?: string[],
    departement?: string[],
    decede?: string,
}
export type INotaireIdentiteFormData = {
    sexe?: string,
    prenom?: string,
    dateNaissance?: string,
    CSN?: string,
    siret?: string,
    dateSermentInitial?: string,
    dateRetrait?: string,
    fonctions?: string[],
    agrementInspection?: string,
    languesParlees?: string[],

    nom?: string,
    lieuNaissance?: string,
    type?: string,
    dateNominationJournalOfficiel?: string,
    dateDernierSerment?: string,
    dateHonorariat?: string,
    labels?: string[],
    agrementComptabilite?: string,
}
export type INotaireUpdateTypeNotaireFormData = {
    dateDepart?: string,

    type?: string,
    officeId?: string,
    dateArrivee?: string,
    dateRetrait?: string,
    dateDeces?: string,
    dateHonorariat?: string,
}
export type INotaireCommentairesFormData = {
    commentaires?: string,
}
export type INotaireGetAllApiResponse = IApiCustomResponse & {
    datas: {
        notaires: INotaire[]
    }
}
export type INotaireHistoriqueFormData = {
    id?: string,
    type: string,
    dateArrivee: string,
    dateDepart: string,
    officeId: string,
    officeType?: string,
    notaireId?: string,
    successeurId?: string,
}
export type INotaireHistoriquesFormData = {
    historiques: INotaireHistoriqueFormData[],
    idsToDelete?: string[]
}
export type INotaireUrssafUploadFormData = {
    fichier: File,
    CRPCEN: string
}

export type INotaireUploadFormData = {
    fichier: File
}
export type INotaireCTRCObserveFormData = {
    ctrcObserveId?: number,
}

export type INotaireFormData = {}

export class NotairesService extends ApiService{
    /**
     * Permet la récupération d'une liste des notaires actifs
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getNotairesWithPaginate(formData: INotaireGetFormData): Promise<INotairesGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires', 'GET', formData);
    }

    /**
     * Permet la récupération d'une liste des anciens notaires
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getAnciensNotairesWithPaginate(formData: INotaireGetFormData): Promise<INotairesGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/anciens', 'GET', formData);
    }


    /**
     * Permet la récupération d'une liste des notaires actifs pour les correspondances
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getNotairesCorrespondancesWithPaginate(formData: INotaireGetFormData): Promise<INotairesGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/correspondances', 'GET', formData);
    }

    /**
     * Permet la récupération d'une liste des anciens notaires pour les correspondances
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getAnciensNotairesCorrespondancesWithPaginate(formData: INotaireGetFormData): Promise<INotairesGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/anciens/correspondances', 'GET', formData);
    }

    /**
     * Permet la récupération d'un notaire à partir de son id
     *
     * @param {string} nid
     * @returns {Promise<INotaireGetApiResponse>}
     */
    public getNotaire(nid: string): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid, 'GET');
    }
    public getNotaireExternal(nid: string): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/external/annuaire/notaires/' + nid, 'GET');
    }


    /**
     *
     * @param {number} nid
     * @param {INotaireIdentiteFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateIdentiteNotaire(nid: number, formDatas: INotaireIdentiteFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid + "/identite", 'POST', formDatas);
    }


    /**
     * Creation d'un notaire
     * @param {INotaireIdentiteFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public create(formDatas: INotaireIdentiteFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/create', 'POST', formDatas);
    }

    /**
     *
     * @param {number} nid
     * @param {INotaireIdentiteFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateCommentaireNotaire(nid: number, formDatas: INotaireCommentairesFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid + "/commentaires", 'POST', formDatas);
    }


    /**
     *
     * @param {number} nid
     * @param {INotaireHistoriquesFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateHistoriques(nid: number, formDatas: INotaireHistoriquesFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid + "/historiques", 'POST', formDatas);
    }

    /**
     *
     * @param {number} nid
     * @param {INotaireUpdateTypeNotaireFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public updateTypeNotaire(nid: number, formDatas: INotaireUpdateTypeNotaireFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid + "/type", 'POST', formDatas);
    }


    /**
     * Permet la récupération d'un liste complète de notaire en activité
     *
     * @returns {Promise<INotaireGetAllApiResponse>}
     */
    public getAll(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/annuaire/notaires/all', 'GET');
    }
    public getAllForSelect(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/annuaire/notaires/all-light', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète de notaire en activité
     *
     * @returns {Promise<INotaireGetAllApiResponse>}
     */
    public getAllExternal(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/external/annuaire/notaires/all', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète de notaire
     *
     * @returns {Promise<INotaireGetAllApiResponse>}
     */
    public getAllCurrentAndOld(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/annuaire/notaires/all-current-old', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète de notaire avec la fonction CTRC - Membre
     *
     * @returns {Promise<INotaireGetAllApiResponse>}
     */
    public getCTRC(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/annuaire/notaires/ctrc', 'GET');
    }

    /**
     * Permet la récupération d'un liste complète de notaire syndic
     *
     * @returns {Promise<INotaireGetAllApiResponse>}
     */
    public getSyndic(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/annuaire/notaires/syndic', 'GET');
    }
    /**
     * Permet la récupération d'un liste complète de notaire syndic
     *
     * @returns {Promise<INotaireGetAllApiResponse>}
     */
    public getPresidentsVicePresidents(): Promise<INotaireGetAllApiResponse>{
        return this.doApiCall('api/annuaire/notaires/presidents-vice-presidents', 'GET');
    }


    /**
     * Permet la suppression d'un notaire
     *
     * @param {string} nid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(nid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid + '/delete', 'POST');
    }



    /**
     * Permet la récupération d'une liste de notaires pouvant avoir une attestation urssaf
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getNotairesAttestationsUrssaf(formData: INotaireGetFormData): Promise<INotairesGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/urssaf', 'GET', formData);
    }


    /**
     * Upload d'une attestation urssaf
     * @param {number} nid
     * @param {number} aid
     * @param {INotaireUrssafUploadFormData} formData
     * @returns {Promise<INotairesGetApiResponse>}
     */
    public uploadUrssafAttestation(nid: number, aid: number, formData: INotaireUrssafUploadFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/'+ nid +'/urssaf/' + aid + '/upload', 'POST', formData);
    }
    public uploadUrssafAttestationExternal(nid: number, aid: number, formData: INotaireUrssafUploadFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/external/annuaire/notaires/'+ nid +'/urssaf/' + aid + '/upload', 'POST', formData);
    }

    /**
     * Suppression d'une attestation urssaf
     * @param {number} nid
     * @param {number} aid
     * @returns {Promise<INotairesGetApiResponse>}
     */
    public deleteUrssafAttestation(nid: number, aid: number): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/'+ nid +'/urssaf/' + aid + '/delete', 'POST', []);
    }

    /**
     * Upload d'un document
     * @param {number} nid
     * @param {FormComponentFormData} formData
     * @returns {Promise<INotairesGetApiResponse>}
     */
    public uploadDocument(nid: number, formData: FormComponentFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/'+ nid +'/documents', 'POST', formData);
    }

    /**
     * Modification du CTRC Observé d'un notaire
     *
     * @param {number} nid
     * @param {INotaireCTRCObserveFormData} formData
     * @returns {Promise<INotaireGetApiResponse>}
     */
    public updateCTRCObserve(nid: number, formData: INotaireCTRCObserveFormData): Promise<INotaireGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/' + nid + '/ctrc-observe', "POST", formData);
    }

    /**
     * GET des événements de notaires
     *
     * @returns {Promise<INotairesEvenementsGetApiResponse>}
     */
    public getEvenements(formData: INotaireGetFormData): Promise<INotairesEvenementsGetApiResponse>{
        return this.doApiCall('api/annuaire/notaires/evenements', 'GET', formData);
    }

}

