import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {INotaire} from "src/Models/Notaire.model";
import {NotairesService} from "src/Services/Notaires.service";
import {IApiCustomResponse} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import useListExportPDF from "../../Hooks/UseListExportPDF";
import StringHelper from "../../Helpers/String.helper";
import ApiService from "src/Services/Api.service";
import { IExportApiResponse } from "src/Services/Api.service";


type IAsideNotaireComponentProps = {
    notaire?: INotaire,
    modificators?: string,
    type: "list" | "fiche" | "listAnciens" | "ficheAnciens" | "listNotairesCtrcObserves" | "ficheHistorique",

    exportCallback?: () => void,
    importCallback?: () => void,

    filters?: any
}

//listNotairesCtrcObserves est utilisé sur SinistresList.screen.tsx pour l'onglet avec les Notaires et leur CTRC observé

export default function AsideNotaireComponent(props: IAsideNotaireComponentProps ) {
    const [printScreen] = usePrint();
    const {exportListPDF} = useListExportPDF();

    const history = useHistory();
    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
        let actions: ListAsideAction[] = [];

        if( props.type === "list") {
            actions.push({icon: "icon-print", click: () => exportPDF('actif')})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-notaires-add", click: () => history.push(AppConstants.pathNotaires + "/creation")});
        }
        if( props.type === "listAnciens") {
            actions.push({icon: "icon-print", click: () => exportPDF('anciens')})
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
            actions.push({icon: "icon-notaires-add", click: () => history.push(AppConstants.pathNotairesAnciens + "/creation")});
        }

        if( props.type === "fiche" || props.type === 'ficheAnciens') {
            let stringHelper = new StringHelper();
            let filename = "notaire-fiche-" + stringHelper.slugify(props.notaire.nom + " "+ props.notaire.prenom);
            actions.push({name: "print-pdf", filename: filename , icon: "icon-print"});
            actions.push({icon: "icon-remove-alternative", click: () => showDeleteConfirmation()});
        } else if( props.type === "listNotairesCtrcObserves") {
            actions.push({icon: "icon-print", click: () => exportPDF('ctrc-observes')});
            actions.push({icon: "icon-export", click: () => props.exportCallback()});
        } else if( props.type !== "list") {
            actions.push({icon: "icon-print", click: () => printScreen()})
        }

        if(props.type === "ficheHistorique") {
            actions.push({icon: "icon-export", click: () => exportHistorique()});
            actions.push({icon: "icon-import", click: () => props.importCallback()});
        }

        setActions(actions);
    }, [props.filters]);



    /**
     * Permet d'affichage l'écran de confirmation
     */
    const showDeleteConfirmation = ():void => {
        setShowConfirmation(true);
    }

    /**
     * Suppression
     */
    const doDelete = (): void => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.delete(props.notaire.id).then((response: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le notaire a bien été supprimé.",
                type: "success"
            });

            if(props.type === 'ficheAnciens') {
                history.push(AppConstants.pathNotairesAnciens);
            }
            else{
                history.push(AppConstants.pathNotaires);
            }

        },(error: IApiCustomResponse) => {
            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la suppression du notaire.",
                    type: "danger"
                });
            }
        });
        setShowConfirmation(false);
    }

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    /**
     * Export de l'historique d'un notaire
     */
    const exportHistorique = (): void => {
        const apiService = new ApiService();
        let exportUrl=`api${AppConstants.pathNotaires}/export-historique`;

        // Passage d'un tableau vide pour la méthode car on ne tri pas pour l'export
        apiService.export(exportUrl, [""]).then((response) => {
            if (response && response.datas.url) {
                const url = response.datas.url;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ``);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
        .catch((e)=>{
            setIsLoading(false);

            console.error(e);
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'export",type: "danger"});
        });
        
    } 



    /**
     * Export pdf de la page de liste
     */
    const exportPDF = (type: string): void => {
        setIsLoading(true);
        //const url:string = type == "actif" ? `api${AppConstants.pathNotaires}/export-pdf` : `api${AppConstants.pathNotaires}/export-pdf/anciens`;
        let url:string = `api${AppConstants.pathNotaires}/export-pdf`;
        if(type == 'anciens') {
            url += '/anciens';
        } else if(type == 'ctrc-observes') {
            //Utilisation de liste/export des notaires (actifs) avec un paramètre pour ajouter les CTRC observés
            url += '?ctrcObserve=export';
        }

        exportListPDF(props.filters, url, () => { setIsLoading(false)});
    }



    return (
        <>
            {
                showConfirmation && props.notaire &&
                <ConfirmationComponent onConfirm={doDelete} onCancel={onCancelConfirmation} text="Souhaitez-vous vraiment supprimer ce notaire ?" />
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
