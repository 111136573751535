import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import ApiService, { IApiCustomResponse } from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useState} from "react";
import FileUploadField from "src/Components/Fields/FileUpload.field";
import { IHistoriquesNotairesUploadFormData } from "src/Services/Api.service";
import { set } from "lodash";
import ErrorsImportHistoriquesNotairesComponent from "src/Components/Forms/ImportHistoriquesNotaires/ErrorsImportHistoriquesNotaires.component";

export type NotairesHistoriquesImportComponentProps = {
    showImport: boolean,
    setShowImport: () => void,
    modificators: string,
    historiquesCallback?: () => void
}

export default function NotairesHistoriquesImportComponent(props: NotairesHistoriquesImportComponentProps) {

    const [importFile, setImportFile] = useState<File>(null);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const onFormSubmit = () => {
        const apiService: ApiService = new ApiService();
        const importUrl = `api${AppConstants.pathNotaires}/import-historique`;
        const exportUrl=`api${AppConstants.pathNotaires}/export-historique`;

        const formData: IHistoriquesNotairesUploadFormData = {
            fichier: importFile
        }

        // On exporte dabord les anciennes valuer puis si l'export réussi alors on procéde a l'import des nouvelles donnée
        apiService.import(importUrl, formData).then((response: IApiCustomResponse) => {
            if (response) {
                setShowErrors(false);
                notificationSystem.addNotification({...defaultNotificationConfig, message: response.messages[0],type: "success"});
                props.historiquesCallback();
                setShowErrors(false);
                props.setShowImport();
            }
        })
        .catch((e)=>{
            setShowErrors(true);
            setErrorMessages(e.messages);
            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de l'import",type: "danger"});
            console.error(e);
        });
    }

    if (!props.showImport) return null;


    const onClose = () => {
        setShowErrors(false);
        props.setShowImport();
    }

    return (
        <>
        <div className={`export-screen ${props.modificators}`}>
            <button className="export-close icon-close" onClick={onClose} />
            <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-secondary"} contentModificators={`-bg-white -no-flex`}>
                <PageHeaderComponent text="Notaires" subText="Import des historiques" icon="icon-import" modificators={`-in-fullscreen`}/>

                <div className="description-text">
                    <p><span className="title -bold">Points essentiels :</span></p>
                    <p><span className="-bold">Champs obligatoires</span></p>
                    <ul>
                        <li>Pour chaque notaire, il est impératif de renseigner au moins un identifiant (ID), un nom et/ou prénom, ou un CSN. Ces informations permettent d'assurer une identification correcte des enregistrements.
                        Le champ "Nom de la fonction" doit également être complété pour valider l'import.
                        Gestion des événements</li>

                        <li>Si une date de sortie n'est pas renseignée, l'événement correspondant ne sera pas enregistré lors de l'import. Veillez à bien vérifier cette information dans le fichier avant de lancer l'opération.
                        Sauvegarde des données existantes</li>

                        <li>Les données existantes seront sauvegardées automatiquement avant chaque import pour éviter toute perte d'informations.
                        Le fichier d'import sera également conservé pour garantir une traçabilité et permettre une éventuelle analyse ou correction future.</li>
                    </ul>
                    <p><span className="-bold">Recommandations :</span></p>
                    Assurez-vous que le fichier d'import respecte le format attendu (vous pouvez vous baser sur l'export) et que tous les champs nécessaires sont correctement remplis avant de lancer l'opération.<br />
                    Il est vivement recommandé de faire une export avant de procéder à l'import pour conserver une copie des données existantes si besoin.
                </div>

                <FileUploadField
                    fieldName="attestation"
                    oldValue=""
                    onChange={(f: File) => setImportFile(f)}
                    background=""
                    modificators=''
                    hideUploadLabelIfFile={false}
                    uploadLabel="Uploader"
                    extensions={['.xlsx', '.xls']}/>

                {showErrors &&
                    <ErrorsImportHistoriquesNotairesComponent errorMessages={errorMessages} />
                }

                <div className="form-actions -outside-right  ">
                    <button className="g-button -is-link" onClick={onClose}>Annuler</button>
                    <button className="g-button -primary" onClick={()=> onFormSubmit()}>Importer</button>
                </div>

            </LayoutComponent>
        </div>
        </>
    )
}