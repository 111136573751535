import { IHistoriquesImportErrorResponse } from "src/Services/Api.service"

export type IErrorsImportHistoriquesNotairesComponentProps = {
    errorMessages: IHistoriquesImportErrorResponse[]
}

export default function ErrorsImportHistoriquesNotairesComponent(props: IErrorsImportHistoriquesNotairesComponentProps) {
    return (
        <div className="form-errors">
            <p className="title">Liste des Erreurs</p>
            {props.errorMessages.map((em, index) =>
                <div key={index} className="error-line">
                    <span className="state-bullet  -before-element icon-bullet -annule"></span>
                    <p><span className="-bold">Numéro de ligne :</span> {em.ligne}</p>
                    {em.notaire !== " " ? <p><span className="-bold">Nom du notaire :</span> {em.notaire}</p> : "" }
                    <p><span className="-bold">Erreur :</span> {em.erreur}</p>
                </div>
            )}
        </div>
    )
}